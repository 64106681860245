<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.453" height="14.544" viewBox="0 0 17.453 14.544">
      <path
        id="Path_15202"
        data-name="Path 15202"
        d="M16,2H1.454A1.454,1.454,0,0,0,0,3.454V15.09a1.454,1.454,0,0,0,1.454,1.454H16a1.454,1.454,0,0,0,1.454-1.454V3.454A1.454,1.454,0,0,0,16,2ZM9.454,13.272a.364.364,0,0,1-.364.364H3.272a.364.364,0,0,1-.364-.364v-.727a.364.364,0,0,1,.364-.364H9.09a.364.364,0,0,1,.364.364ZM9.09,7.091a1.091,1.091,0,1,1,1.091,1.091A1.091,1.091,0,0,1,9.09,7.091Zm5.454,6.181a.364.364,0,0,1-.364.364H11.272a.364.364,0,0,1-.364-.364v-.727a.364.364,0,0,1,.364-.364h2.909a.364.364,0,0,1,.364.364Zm-.873-3.563a.727.727,0,1,1-1.164-.873,2.909,2.909,0,0,0,0-3.491.727.727,0,0,1,1.164-.873,4.363,4.363,0,0,1,0,5.236Z"
        transform="translate(0 -2)"
        :fill="verification ? colour : noColour"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    verification: {
      type: Boolean,
    },
  },
  data() {
    return {
      colour: "#FECA35",
      noColour: "rgba(255, 255, 255, 0.5)",
    };
  },
};
</script>
